class ZIDXFileUploadProgress{
    formElement:HTMLFormElement;
    fieldInput:HTMLInputElement;
    previewDiv:HTMLDivElement;
    fileInput:HTMLInputElement;
    urlElement:HTMLInputElement;
    isImage:boolean;
    isUserTable:boolean;
    imageSizes:string;
    progressBar:HTMLProgressElement;
    constructor(formElement:HTMLFormElement, previewDiv:HTMLDivElement, fieldInput:HTMLInputElement, fileInput:HTMLInputElement, isImage:boolean, isUserTable:boolean, imageSizes:string, urlElement:HTMLInputElement, progressBar:HTMLProgressElement) {
        this.formElement=formElement;
        this.previewDiv=previewDiv;
        this.fieldInput=fieldInput;
        this.fileInput=fileInput;
        this.isImage=isImage;
        this.imageSizes=imageSizes;
        this.isUserTable=isUserTable;
        this.urlElement=urlElement;
        this.progressBar=progressBar;
    }
    doUpload() {
        let self = this;
        let formData = new FormData();
        if(!this.fileInput.files){
            console.log("No files to upload");
            return;
        }
        let fileUploadingField=<HTMLInputElement>document.getElementById("fileUploadingField"+this.formElement.id);
        let uploading=parseInt(fileUploadingField.value);
        console.log("uploading value:", uploading, fileUploadingField, fileUploadingField.value);
        uploading++;
        fileUploadingField.value=uploading.toString();
        if(this.fileInput.multiple) {
            formData.append("multiple", "1");
        }else{
            formData.append("multiple", "0");
            self.previewDiv.innerHTML='';
        }
        for(let i=0;i<this.fileInput.files.length;i++){
            let currentFile:File=this.fileInput.files[i];
            let div=document.createElement("div");
            if(this.isImage) {
                div.className="zidxLocalPreviewImageContainer";
                let spinner=document.createElement("i");
                spinner.className="fa fa-refresh zidxLocalPreviewSpinner";
                spinner.setAttribute("aria-hidden", "true");
                div.appendChild(spinner);
                let img = document.createElement("img");
                img.setAttribute("style", "opacity:0.7; max-width:100%; max-height:100%;");
                // img.id="zidxPreviewImage"+(new Date().getTime());
                img.src = URL.createObjectURL(currentFile);
                img.onload = function () {
                    URL.revokeObjectURL(img.src) // free memory
                };
                div.appendChild(img);
            }else{
                div.className="zidxPreviewFileContainer";
                let span = document.createElement("span");
                span.innerText="Uploading File: "+currentFile.name;
                div.appendChild(span);
                let span2 = document.createElement("span");
                span2.setAttribute("style", "float:left;");
                let spinner=document.createElement("i");
                spinner.className="fa fa-refresh zidxPreviewSpinner";
                spinner.setAttribute("aria-hidden", "true");
                span2.appendChild(spinner);
                div.appendChild(span2);
            }
            self.previewDiv.appendChild(div);
            // currentFile.type and currentFile.size also exist
            formData.append("files", currentFile, currentFile.name);
        }
        this.fileInput.value="";
        formData.append("previousIds", this.fieldInput.value);
        if(this.isUserTable) {
            formData.append("userTable", "1");
        }else{
            formData.append("userTable", "0");
        }
        formData.append("imageSizes", this.imageSizes);
        let client = new XMLHttpRequest();
        let url="/account/upload-file";
        if(this.isImage){
            url="/account/upload-image";
        }
        client.open("POST", url);
        client.upload.onprogress = function(pe) {
            if(pe.lengthComputable) {
                self.progressBar.max = pe.total;
                self.progressBar.value = pe.loaded;
                console.log('progress', pe.total, pe.loaded);
            }else{
                console.log("Can't compute upload progress");
            }
        }
        client.upload.onloadend = function(pe) {
            self.progressBar.style.display='none';
            console.log('upload complete', pe);
        }
        client.onloadend = function(pe) {
            self.progressBar.value = pe.loaded;
            let uploading=parseInt(fileUploadingField.value);
            uploading--;
            fileUploadingField.value=uploading.toString();
            let js=JSON.parse(this.responseText);
            // console.log('response received', pe, this.responseText, js);
            if(!js.success){
                alert(js.errorMessage);
                return;
            }
            let currentValue=self.fieldInput.value;
            let currentPreview=self.previewDiv.innerHTML;
            let currentValueURL=self.urlElement.value;
            let fileData=JSON.parse(js.data);
            if(!self.fileInput.multiple) {
                currentValue="";
                currentPreview="";
                currentValueURL="";
            }
            ZIDX.$(".zidxLocalPreviewImageContainer").detach();
            for(let i=0;i<fileData.length;i++){
                if(currentValue.length!=0) {
                    currentValue += ",";
                    currentValueURL += ",";
                }
                if(self.isImage) {
                    currentPreview += '<div class="zidxPreviewImageContainer"><img src="' + fileData[i].link + '" alt="Preview" style="max-width:100%; max-height:100%;"></div>';
                }else{
                    currentPreview += '<div class="zidxPreviewFileContainer"><a href="' + fileData[i].link + '" class="zidxManagerSearchButton" target="_blank">Download ('+fileData[i].name+')</a></div>';
                }
                currentValue+=fileData[i].id;
                currentValueURL+=fileData[i].link;
            }
            self.urlElement.value=currentValueURL;
            self.fieldInput.value=currentValue;
            self.previewDiv.innerHTML=currentPreview;
        }
        client.send(formData);
    }
}